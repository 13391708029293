
/* TRANSATIONS */
export const GET_TRANSATIONS = "GET_TRANSATIONS"
export const GET_TRANSATIONS_SUCCESS = "GET_TRANSATIONS_SUCCESS"
export const GET_TRANSATIONS_FAIL = "GET_TRANSATIONS_FAIL"

/**
 * add TRANSATION
 */
export const ADD_NEW_TRANSATION = "ADD_NEW_TRANSATION"
export const ADD_TRANSATION_SUCCESS = "ADD_TRANSATION_SUCCESS"
export const ADD_TRANSATION_FAIL = "ADD_TRANSATION_FAIL"

/**
 * Edit TRANSATION
 */
export const UPDATE_TRANSATION = "UPDATE_TRANSATION"
export const UPDATE_TRANSATION_SUCCESS = "UPDATE_TRANSATION_SUCCESS"
export const UPDATE_TRANSATION_FAIL = "UPDATE_TRANSATION_FAIL"

/**
 * Delete TRANSATION
 */
export const DELETE_TRANSATION = "DELETE_TRANSATION"
export const DELETE_TRANSATION_SUCCESS = "DELETE_TRANSATION_SUCCESS"
export const DELETE_TRANSATION_FAIL = "DELETE_TRANSATION_FAIL"



