import React from "react";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

document.title = "Transactions | DST Tax Rate";

const DstTaxRate = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Docs & API" breadcrumbItem="DST Tax Rate" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Documentation: Digital Service Tax (DST) Tax Rate API</CardTitle>
                  <p>Welcome to the documentation for the Digital Service Tax (DST) Tax Rate API.</p>
                  <p>This API provides endpoints for retrieving tax rates, remitting taxes, and calculating taxes for digital services.</p>
                  <h5>Endpoints:</h5>
                  <ul>
                    <li>
                      <strong>GET /tax-rates</strong>: Retrieve all tax rates for different digital services.
                    </li>
                    <li>
                      <strong>POST /remit-tax</strong>: Remit taxes for a specific digital service transaction.
                    </li>
                    <li>
                      <strong>POST /calculate-tax</strong>: Calculate taxes for a digital service transaction based on the service type and transaction amount.
                    </li>
                  </ul>
                  <h5>Usage:</h5>
                  <p>To use this API, send HTTP requests to the specified endpoints with the required parameters.</p>
                  <p>For more details, refer to the API documentation.</p>

                  {/* Code Snippets */}
                  <div className="mt-4 p-3 bg-light rounded">
                    <h5 className="mb-3">Example: Calculate Tax Request</h5>
                    <code>
                      {`
                        POST /calculate-tax HTTP/1.1
                        Host: example.com
                        Content-Type: application/json

                        {
                          "serviceType": "digital-product",
                          "transactionAmount": 100
                        }
                      `}
                    </code>
                  </div>

                  {/* Additional Details */}
                  <h5 className="mt-4">Additional Details:</h5>
                  <p>
                    The tax rates returned by the <code>/tax-rates</code> endpoint are in percentage format and vary based on the type of digital service.
                    The <code>/remit-tax</code> endpoint requires authentication and authorization to remit taxes. Only authorized users can access this endpoint.
                  </p>
                  <p>
                    The <code>/calculate-tax</code> endpoint calculates taxes based on the specified service type and transaction amount. It returns the calculated tax amount in the response.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DstTaxRate;