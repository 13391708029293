import {
  GET_MEMBERS_FAIL,
  GET_MEMBERS_SUCCESS,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  product: {},
  members: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true
};

const Member = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload,
        loading: true
      };

    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        members: [...state.members, action.payload],
      };

    case ADD_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.map(member =>
          (member.id + '') === (action.payload.id + '')
            ? { member, ...action.payload }
            : member
        ),
      };

    case UPDATE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter(
          member => member.id !== action.payload
        ),
      };

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Member;
