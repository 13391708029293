import React from "react";
import { Container, Card, CardBody, CardTitle, Row, Col } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

document.title = "Dashboard | Remit DST"

const RemitDst = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Docs & API" breadcrumbItem="Remit DST" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Documentation: Remit Digital Service Tax (DST)</CardTitle>
                  <p>Welcome to the documentation for remitting Digital Service Tax (DST).</p>
                  <p>This documentation provides information on how to remit DST for digital service transactions.</p>
                  <h5>Process:</h5>
                  <ol>
                    <li>
                      <strong>Authenticate:</strong> Authenticate with the DST tax authority to remit taxes.
                    </li>
                    <li>
                      <strong>Submit Transaction Details:</strong> Provide transaction details, including service type, transaction amount, and payer information.
                    </li>
                    <li>
                      <strong>Calculate Tax:</strong> Calculate the DST amount based on the transaction details and applicable tax rates.
                    </li>
                    <li>
                      <strong>Remit Tax:</strong> Remit the calculated DST amount to the tax authority within the specified timeframe.
                    </li>
                    <li>
                      <strong>Receive Confirmation:</strong> Receive confirmation of tax remittance from the tax authority.
                    </li>
                  </ol>
                  <h5>Usage:</h5>
                  <p>To remit DST, follow the steps outlined above and adhere to the guidelines provided by the DST tax authority.</p>
                  <p>For more details, refer to the official documentation provided by the tax authority.</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RemitDst;