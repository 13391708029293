
/* MEMBERS */
export const GET_MEMBERS = "GET_MEMBERS"
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS"
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL"

/**
 * add MEMBER
 */
export const ADD_NEW_MEMBER = "ADD_NEW_MEMBER"
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS"
export const ADD_MEMBER_FAIL = "ADD_MEMBER_FAIL"

/**
 * Edit MEMBER
 */
export const UPDATE_MEMBER = "UPDATE_MEMBER"
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS"
export const UPDATE_MEMBER_FAIL = "UPDATE_MEMBER_FAIL"

/**
 * Delete MEMBER
 */
export const DELETE_MEMBER = "DELETE_MEMBER"
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS"
export const DELETE_MEMBER_FAIL = "DELETE_MEMBER_FAIL"



