import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import applications from "./applications/reducer";
import members from "./members/reducer";
import transactions from "./transactions/reducer";
import users from "./users/reducer";
import groups from "./groups/reducer";
import claims from "./claims/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  applications,
  transactions,
  members,
  users,
  groups,
  claims
});

export default rootReducer;
