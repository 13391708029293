import {
  GET_TRANSATIONS_FAIL,
  GET_TRANSATIONS_SUCCESS,
  ADD_TRANSATION_SUCCESS,
  ADD_TRANSATION_FAIL,
  UPDATE_TRANSATION_SUCCESS,
  UPDATE_TRANSATION_FAIL,
  DELETE_TRANSATION_SUCCESS,
  DELETE_TRANSATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  products: [],
  product: {},
  transactions: [],
  cartData: {},
  customers: [],
  productComments: [],
  shops: [],
  error: {},
  loading: true
};

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_TRANSATIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: true
      };

    case GET_TRANSATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_TRANSATION_SUCCESS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      };

    case ADD_TRANSATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TRANSATION_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.map(transaction =>
          (transaction.id + '') === (action.payload.id + '')
            ? { transaction, ...action.payload }
            : transaction
        ),
      };

    case UPDATE_TRANSATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TRANSATION_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.filter(
          transaction => transaction.id !== action.payload
        ),
      };

    case DELETE_TRANSATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Transaction;
