import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Table,
  Input,
  Label,
  Row,
} from "reactstrap";
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Profile = () => {

  const [userData, setUserData] = useState({
    userId: "",
    merchantId: "",
    email: "",
    firstName: "",
    lastName: "",
    role: "",
    authToken: "",
  });

  // Load user data from local storage on component mount
  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser) {
      setUserData(authUser);
    }
  }, []);

  //meta title
  document.title = "Add Member | SUN Welfare Administration System";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Account" breadcrumbItem="Profile" />
          <Row>
            <Col xl="4" className="mt-4">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">SENTRY</h5>
                        <p>Account Details</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={avatar1}
                          alt="profile-pic"
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">{userData.firstName}</h5>
                      <p className="text-muted mb-0 text-truncate">{userData.role}</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>


              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                  <p className="text-muted mb-4">
                    Here!
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{userData.firstName || ""} {userData.lastName || ""}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{"+254 789 345 677"}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{userData.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{"00100 GPO, Nairobi"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8" className="mt-4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Edit Profile Information</CardTitle>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">First Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            placeholder={userData.firstName || ""}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Last Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-lastname-Input"
                            placeholder= {userData.lastName || ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Mobile Number</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-phone-Input"
                            placeholder="Enter Your Mobile Number"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Email</Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder={userData.email || ""}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputCity"
                            placeholder="Enter Your Living City"
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">State</Label>
                          <select
                            id="formrow-InputState"
                            className="form-control"
                          >
                            <option defaultValue>Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Zip</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-InputZip"
                            placeholder="Enter Your Zip Code"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        SAVE
                      </button>
                    </div>
                  </Form>

                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment >
  )
}

export default Profile