/* CLAIMS */
export const GET_CLAIMS = "GET_CLAIMS"
export const GET_CLAIMS_SUCCESS = "GET_CLAIMS_SUCCESS"
export const GET_CLAIMS_FAIL = "GET_CLAIMS_FAIL"

/**
 * add CLAIM
 */
export const ADD_NEW_CLAIM = "ADD_NEW_CLAIM"
export const ADD_CLAIM_SUCCESS = "ADD_CLAIM_SUCCESS"
export const ADD_CLAIM_FAIL = "ADD_CLAIM_FAIL"

/**
 * Edit CLAIM
 */
export const UPDATE_CLAIM = "UPDATE_CLAIM"
export const UPDATE_CLAIM_SUCCESS = "UPDATE_CLAIM_SUCCESS"
export const UPDATE_CLAIM_FAIL = "UPDATE_CLAIM_FAIL"

/**
 * Delete CLAIM
 */
export const DELETE_CLAIM = "DELETE_CLAIM"
export const DELETE_CLAIM_SUCCESS = "DELETE_CLAIM_SUCCESS"
export const DELETE_CLAIM_FAIL = "DELETE_CLAIM_FAIL"
