import React from "react";
import { Navigate } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";
import Settings from "../pages/Authentication/Settings";
import Documents from "../pages/Authentication/documents";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import Applications from "../pages/applications/index";
import Members from "../pages/Members/index";
import Groups from "../pages/Groups/index";
import Claims from "../pages/Claim/index";
import Users from "../pages/DSTTaxRate/index";
import Roles from "../pages/RemitDST/index";
import Transactions from "../pages/Transactions/index";
import Communications from "../pages/Communications/index";
import Covers from "../pages/Covers/index";
import Products from "../pages/Products/index";
import BenefitTypes from "../pages/BenefitTypes/index";
import Benefits from "../pages/Benefits/index";
import RiskClass from "../pages/RiskClass/index";
import Categories from "../pages/Categories/index";
import MemberReports from "../pages/Reports/MemberReports";
import GroupReports from "../pages/Reports/GroupReports";
import ClaimReports from "../pages/Reports/ClaimReports";

//Forms
import AddApplication from "../pages/applications/AddApplication";
import AddGroup from "../pages/Groups/AddGroup";
import AddClaim from "../pages/Claim/AddClaim";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/settings", component: <Settings /> },
  { path: "/documents", component: <Documents />},

  //pages
  { path: "/dstTaxRates", component: <Users /> },
  { path: "/remitDst", component: <Roles /> },
  { path: "/transactions", component: <Transactions /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
