import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  getTransactions as onGetTransactions,
  addNewTransaction as onAddNewTransaction,
  updateTransaction as onUpdateTransaction,
  deleteTransaction as onDeleteTransaction,
} from "store/actions";

import {
  TransactionId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./TransactionCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import TransactionsModal from "./TransactionModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";

function Transaction() {

  const generateFakeData = () => {
    const fakeData = [
      {
        id: 1,
        transactionId: "CR57 5392 3761 5412 9680 5",
        amount: "$100",
        merchantName: "Antares Pharma, Inc.",
        transactionDate: "2/8/2024",
        paymentMethod: "mastercard",
        status: "false"
      }, {
        id: 2,
        transactionId: "RS78 6229 7148 8074 7810 74",
        amount: "100 TSHs",
        merchantName: "Otelco Inc.",
        transactionDate: "3/11/2023",
        paymentMethod: "switch",
        status: "false"
      }, {
        id: 3,
        transactionId: "TN72 7911 3188 6470 3216 5199",
        amount: "$900",
        merchantName: "First Trust Dorsey Wright Focus 5 ETF",
        transactionDate: "9/19/2023",
        paymentMethod: "maestro",
        status: "true"
      }, {
        id: 4,
        transactionId: "LB45 1134 VRGB F339 S3XP B4Q2 MLTY",
        merchantName: "Spectrum Brands Holdings, Inc.",
        amount: "¥6.2442",
        transactionDate: "7/5/2023",
        paymentMethod: "switch",
        status: "false"
      }, {
        id: 5,
        transactionId: "FR65 3347 8227 47OC JCIR RKMS U44",
        amount: "KES 24444",
        merchantName: "Cohen & Steers Inc",
        transactionDate: "2/25/2023",
        paymentMethod: "jcb",
        status: "true"
      }, {
        id: 6,
        transactionId: "BE10 8133 3692 0595",
        amount: "£7.79",
        merchantName: "Principal Price Setters Index ETF",
        transactionDate: "10/16/2023",
        paymentMethod: "jcb",
        status: "true"
      }, {
        id: 7,
        transactionId: "GI44 TZZS 8JLV 7FQ8 S4O6 W2J",
        amount: "¥6.77",
        merchantName: "SunTrust Banks, Inc.",
        transactionDate: "3/9/2023",
        paymentMethod: "mastercard",
        status: "true"
      }, {
        id: 8,
        transactionId: "CH07 1406 9EV2 D1ZT 4QZ2 L",
        amount: "£1.50",
        merchantName: "Global X FinTech ETF",
        transactionDate: "3/21/2023",
        paymentMethod: "switch",
        status: "true"
      }, {
        id: 9,
        transactionId: "FO05 3740 8057 7886 97",
        amount: "$2.10",
        merchantName: "Cogentix Medical, Inc.",
        transactionDate: "10/31/2023",
        paymentMethod: "mastercard",
        status: "true"
      },
      {
        id: 10,
        transactionId: "SA4517JKD9",
        amount: "KES 100",
        merchantName: "Netflix",
        transactionDate: "24-02-2024",
        paymentMethod: "MPESA",
        status: "Success",
      },
    ];

    return fakeData;
  };

  //meta title
  document.title = "Transactions | Transactions";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [transaction, setTransaction] = useState(null);

  const transactionsCardData = [
    { title: "Total Transactions", iconClass: "bx bx-transfer" , description: "$35,400"},
    { title: "Total Digital Sales Tax", iconClass: "bx bx-dollar-circle", description: "$4,000" },
    { title: "Total Sales", iconClass: "bx bx-shopping-bag", description: "$7,500" },
];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      transactionId: (transaction && transaction.transactionId) || '',
      billingName: (transaction && transaction.billingName) || '',
      transactiondate: (transaction && transaction.transactiondate) || '',
      total: (transaction && transaction.total) || '',
      paymentStatus: (transaction && transaction.paymentStatus) || 'Paid',
      badgeclass: (transaction && transaction.badgeclass) || 'success',
      paymentMethod: (transaction && transaction.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      transactionId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Transaction Id"
        ).required("Please Enter Your Transaction Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      transactiondate: Yup.string().required("Please Enter Your Transaction Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateTransaction = {
          id: transaction ? transaction.id : 0,
          transactionId: values.transactionId,
          billingName: values.billingName,
          transactiondate: values.transactiondate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update transaction
        dispatch(onUpdateTransaction(updateTransaction));
        validation.resetForm();
      } else {
        const newTransaction = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          transactionId: values["transactionId"],
          billingName: values["billingName"],
          transactiondate: values["transactiondate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new transaction
        dispatch(onAddNewTransaction(newTransaction));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();

  const selectTransactionState = (state) => state.transactions;
  const TransactionProperties = createSelector(
    selectTransactionState,
    (Transaction) => ({
      transactions: generateFakeData(),
      // TO-DO return this for live data
      // transactions: Transaction.transactions,
      loading: Transaction.loading
    })
  );

  const { transactions, loading } = useSelector(TransactionProperties);

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (transactions && !transactions.length) {
      dispatch(onGetTransactions());
    }
  }, [dispatch, transactions]);

  useEffect(() => {
    if (!isEmpty(transactions) && !!isEdit) {
      setIsEdit(false);
    }
  }, [transactions]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTransaction(null);
    } else {
      setModal(true);
    }
  };

  const handleTransactionClick = arg => {
    const transaction = arg;
    setTransaction({
      id: transaction.id,
      transactionId: transaction.transactionId,
      billingName: transaction.billingName,
      transactiondate: transaction.transactiondate,
      total: transaction.total,
      paymentStatus: transaction.paymentStatus,
      paymentMethod: transaction.paymentMethod,
      badgeclass: transaction.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  //delete transaction
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (transaction) => {
    setTransaction(transaction);
    setDeleteModal(true);
  };

  const handleDeleteTransaction = () => {
    if (transaction && transaction.id) {
      dispatch(onDeleteTransaction(transaction.id));
      setDeleteModal(false);
    }
  };
  const handleTransactionClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'TRANSACTION ID',
        accessor: 'transactionId',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionId {...cellProps} />;
        }
      },
      {
        Header: 'AMOUNT',
        accessor: 'amount',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'MERCHANT NAME',
        accessor: 'merchantName',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'TRANSACTION DATE',
        accessor: 'transactionDate',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'PAYMENT METHOD',
        accessor: 'paymentMethod',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        }
      },
      // {
      //   Header: 'View Details',
      //   accessor: 'view',
      //   disableFilters: true,
      //   Cell: () => {
      //     return (
      //       <Button
      //         type="button"
      //         color="primary"
      //         className="btn-sm btn-rounded"
      //         onClick={toggleViewModal}
      //       >
      //         View Details
      //       </Button>);
      //   }
      // },
      {
        Header: 'ACTIONS',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const transactionData = cellProps.row.original;
                  handleTransactionClick(transactionData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const transactionData = cellProps.row.original;
                  onClickDelete(transactionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TransactionsModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTransaction}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transactions" />
          <Row>
            {/* Reports Render */}
            {transactionsCardData.map((report, key) => (
              <Col md="4" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                          {report.title}
                        </p>
                        <h4 className="mb-0">{report.description}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        columns={columns}
                        data={transactions}
                        isGlobalFilter={true}
                        // isAddOptions={true}
                        handleTransactionClicks={handleTransactionClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Transaction" : "Add Transaction"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Transaction Id</Label>
                      <Input
                        name="transactionId"
                        type="text"
                        placeholder="Insert Transaction Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.transactionId || ""}
                        invalid={
                          validation.touched.transactionId && validation.errors.transactionId ? true : false
                        }
                      />
                      {validation.touched.transactionId && validation.errors.transactionId ? (
                        <FormFeedback type="invalid">{validation.errors.transactionId}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Billing Name</Label>
                      <Input
                        name="billingName"
                        type="text"
                        placeholder="Insert Billing Name"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.billingName || ""}
                        invalid={
                          validation.touched.billingName && validation.errors.billingName ? true : false
                        }
                      />
                      {validation.touched.billingName && validation.errors.billingName ? (
                        <FormFeedback type="invalid">{validation.errors.billingName}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Transaction Date</Label>
                      <Flatpickr
                        className="form-control d-block"
                        name="transactiondate"
                        placeholder="Select time"
                        options={{
                          dateFormat: 'd M, Y',
                        }}
                        onChange={(date) => validation.setFieldValue("transactiondate", moment(date[0]).format("DD MMMM, YYYY"))}
                        value={validation.values.transactiondate}
                      />
                      {validation.touched.transactiondate && validation.errors.transactiondate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.transactiondate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Total</Label>
                      <Input
                        name="total"
                        type="text"
                        placeholder="Insert Total Amount"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.total || ""}
                        invalid={
                          validation.touched.total && validation.errors.total ? true : false
                        }
                      />
                      {validation.touched.total && validation.errors.total ? (
                        <FormFeedback type="invalid">{validation.errors.total}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Status</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentStatus || ""
                        }
                      >
                        <option>Paid</option>
                        <option>Chargeback</option>
                        <option>Refund</option>
                      </Input>
                      {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Badge Class</Label>
                      <Input
                        name="badgeclass"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.badgeclass || ""}
                      >
                        <option>success</option>
                        <option>danger</option>
                        <option>warning</option>
                      </Input>
                      {validation.touched.badgeclass && validation.errors.badgeclass ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.badgeclass}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Payment Method</Label>
                      <Input
                        name="paymentMethod"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.paymentMethod || ""
                        }
                      >
                        <option>Mastercard</option>
                        <option>Visa</option>
                        <option>Paypal</option>
                        <option>COD</option>
                      </Input>
                      {validation.touched.paymentMethod && validation.errors.paymentMethod ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentMethod}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
Transaction.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Transaction;
