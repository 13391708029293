import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { registerUser, apiError } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link, useNavigate } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";

const Register = props => {
  document.title = "Register | Skote - React Admin & Dashboard Template";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      password: "",
      companyName: "",
      mobileNumber: "",
      userType: "COMPANY"
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string(),
      middleName: Yup.string(),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
      companyName: Yup.string().required("Please Enter Your Company Name"),
      mobileNumber: Yup.string().required("Please Enter Your Mobile Number"),
      userType: Yup.string().required("Please Select Your User Type"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await fetch("https://app.sentrysoftware.ltd/api/auth/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(values)
        });

        if (!response.ok) {
          throw new Error("Registration failed");
        }

        const data = await response.json();
        dispatch(registerUser(data));
        setTimeout(() => navigate("/login"), 2000);
      } catch (error) {
        dispatch(apiError(error.message));
      }
    }
  });

  const selectAccountState = (state) => state.Account;
  const AccountProperties = createSelector(
    selectAccountState,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      success: account.success
    })
  );

  const {
    user,
    registrationError, success
  } = useSelector(AccountProperties);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000);
  }, [success]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Skote account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={validation.handleSubmit}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      {/* Input fields go here */}
                      <Row>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              id="firstName"
                              name="firstName"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.touched.firstName && validation.errors.firstName ? true : false
                              }
                            />
                            {validation.touched.firstName && validation.errors.firstName ? (
                              <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                            ) : null}

                          </div>
                        </Col>

                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="lastName"
                              type="text"
                              placeholder="Enter Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.touched.lastName && validation.errors.lastName ? true : false
                              }
                            />
                            {validation.touched.lastName && validation.errors.lastName ? (
                              <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Middle Name</Label>
                            <Input
                              name="middleName"
                              type="text"
                              placeholder="Enter Middle Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.middleName || ""}
                              invalid={
                                validation.touched.middleName && validation.errors.middleName ? true : false
                              }
                            />
                            {validation.touched.middleName && validation.errors.middleName ? (
                              <FormFeedback type="invalid">{validation.errors.middleName}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <Input
                              name="companyName"
                              type="text"
                              placeholder="Enter Company Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.companyName || ""}
                              invalid={
                                validation.touched.companyName && validation.errors.companyName ? true : false
                              }
                            />
                            {validation.touched.companyName && validation.errors.companyName ? (
                              <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">Mobile Number</Label>
                            <Input
                              name="mobileNumber"
                              type="tel"
                              placeholder="Enter Mobile Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobileNumber || ""}
                              invalid={
                                validation.touched.mobileNumber && validation.errors.mobileNumber ? true : false
                              }
                            />
                            {validation.touched.mobileNumber && validation.errors.mobileNumber ? (
                              <FormFeedback type="invalid">{validation.errors.mobileNumber}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6} lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">User Type</Label>
                            <Input
                              type="select"
                              name="userType"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.userType || ""}
                              invalid={
                                validation.touched.userType && validation.errors.userType ? true : false
                              }
                            >
                              <option value="">Select User Type</option>
                              <option value="COMPANY">Company</option>
                              <option value="CUSTOMER">Individual</option>
                            </Input>
                            {validation.touched.userType && validation.errors.userType ? (
                              <FormFeedback type="invalid">{validation.errors.userType}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block w-100"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Sentry{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;