import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Row } from "reactstrap";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Settings = () => {
  const [memberData, setMemberData] = useState({
    profileImage: avatar1,
    email: "example@example.com",
    password: "********", // Password is hidden
  });

  // Update memberData state when component mounts
  useEffect(() => {
    // Fetch member data from server or local storage
    // For demonstration, I'll just set some sample data
    const fetchData = async () => {
      // Sample data
      const sampleData = {
        profileImage: avatar1,
        email: "example@example.com",
        password: "********",
      };
      setMemberData(sampleData);
    };

    fetchData();
  }, []);

  // Function to handle changing profile image
  const handleChangeProfileImage = () => {
    // Implement logic to change profile image
    console.log("Changing profile image...");
  };

  // Function to handle changing email
  const handleChangeEmail = () => {
    // Implement logic to change email
    console.log("Changing email...");
  };

  // Function to handle changing password
  const handleChangePassword = () => {
    // Implement logic to change password
    console.log("Changing password...");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Account" breadcrumbItem="Settings" />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Settings</CardTitle>
                  <div className="text-center">
                    <img src={memberData.profileImage} alt="Profile" className="avatar-lg rounded-circle" />
                    <div className="mt-3">
                      <Button type="button" color="primary" className="btn-sm" onClick={handleChangeProfileImage}>Change Profile Image</Button>
                    </div>
                  </div>

                  <Form>
                    <div className="mb-3">
                      <Label htmlFor="email">Email</Label>
                      <Input type="email" id="email" name="email" value={memberData.email} onChange={handleChangeEmail} />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="password">Password</Label>
                      <Input type="password" id="password" name="password" value={memberData.password} onChange={handleChangePassword} />
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;