import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import TableContainer from '../../components/Common/TableContainer';
import SplineArea from "./SplineArea"
import Spinners from "components/Common/Spinner";

import {
  TransactionId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./TransactionCol";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const Dashboard = props => {

  const generateFakeData = () => {
    const fakeData = [
      {
        id: 1,
        salesAmount: "1,897",
        dateTime: "12-02-2024",
        currency: "$4234",
        dstPercentage: "14%",
        dstAmount: "19",
        dstRemitted: "19",
        remitDate: "12-02-2024",
      },
      {
        id: 2,
        salesAmount:"330110",
        dateTime:"6-29-2023",
        currency:'$1000',
        dstPercentage: "4%",
        dstAmount:"79",
        dstRemitted:"12",
        remitDate:"9/20/2023"
    },
{
    id: 3,
    salesAmount:"983734",
    dateTime:"10/4/2023",
    currency:"$2398",
    dstPercentage: "5%",
    dstAmount:"25",
    dstRemitted:"83",
    remitDate:"12/10/2023"
},
{
    id: 4,
    salesAmount:"701847",
    dateTime:"4/28/2023",
    currency:"$324",
    dstPercentage: "48%",
    dstAmount:"42",
    dstRemitted:"75",
    remitDate:"9/28/2023"
},
{
    id: 5,
    salesAmount:"719011",
    dateTime:"4/11/2023",
    currency:"$2453",
    dstPercentage: "15%",
    dstAmount:"56",
    dstRemitted:"99",
    remitDate:"11/17/2023"
},
{
    id: 6,
    salesAmount:"830426",
    dateTime:"3/15/2023",
    currency:"$898",
    dstPercentage: "36%",
    dstAmount:"9",
    dstRemitted:"97",
    remitDate:"1/3/2024"
},
{
    id: 7,
    salesAmount:"771915",
    dateTime:"7/23/2023",
    currency:"$564",
    dstPercentage: "28%",
    dstAmount:"83",
    dstRemitted:"27",
    remitDate:"10/5/2023"
},
{
    id: 8,
    salesAmount:"784349",
    dateTime:"12/17/2023",
    currency:"$098",
    dstPercentage: "73%",
    dstAmount:"5",
    dstRemitted:"36",
    remitDate:"9/16/2023"
},
{
    id: 9,
    salesAmount:"296049",
    dateTime:"9/2/2023",
    currency:"$988",
    dstPercentage: "95%",
    dstAmount:"33",
    dstRemitted:"76",
    remitDate:"8/16/2023"
},
{
    id: 10,
    salesAmount:"684272",
    dateTime:"10/30/2023",
    currency:"$8989",
    dstPercentage: "46%",
    dstAmount:"38",
    dstRemitted:"30",
    remitDate:"1/10/2024"
},

    ];

    return fakeData;
  };

  const handleTransactionClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const selectTransactionState = (state) => state.transactions;
  const TransactionProperties = createSelector(
    selectTransactionState,
    (Transaction) => ({
      transactions: generateFakeData(),
      // TO-DO return this for live data
      // transactions: Transaction.transactions,
      loading: Transaction.loading
    })
  );

  const { transactions, loading } = useSelector(TransactionProperties);
  const [isLoading, setLoading] = useState(loading)

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="form-check font-size-16" >
          <input className="form-check-input" type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: '#',
        width: '20px',
        filterable: true,
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <input className="form-check-input" type="checkbox" id="checkAll" />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: 'SALES AMOUNT',
        accessor: 'salesAmount',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <TransactionId {...cellProps} />;
        }
      },
      {
        Header: 'DATE TIME',
        accessor: 'dateTime',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'CURRENCY',
        accessor: 'currency',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'DST%',
        accessor: 'dstPercentage',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'DST AMOUNT',
        accessor: 'dstAmount',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'DST REMITTED',
        accessor: 'dstRemitted',
        Cell: (cellProps) => {
          return <PaymentMethod {...cellProps} />;
        }
      },
      {
        Header: 'REMIT DATE',
        accessor: 'remitDate',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'ACTIONS',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const transactionData = cellProps.row.original;
                  handleTransactionClick(transactionData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const transactionData = cellProps.row.original;
                  onClickDelete(transactionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  //meta title
  document.title = "Dashboard | SENTRY MERCHANT"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col xl={8}>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                      header={props.t("Admin Dashboard")}
                      title={props.t("Dashboards")}
                      breadcrumbItem={props.t("Digital Sales Tax")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xl={6}>
                    {/* <div>
                      <p className="text-muted mb-1">
                        Sales & Digital Sales Tax(DST)
                      </p>
                    </div> */}
                    <Row className="py-1">
                      <Col xs={4}>
                        <div className="mt-3">
                          <div className="d-flex">
                            <p className="text-muted mb-1">Kes.</p>
                            <h2>36,420 </h2>
                          </div>
                          <p className="text-muted mb-1">Total Profit</p>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6}></Col>
                </Row>
                <CardTitle className="mb-4"> Sales & Digital Sales Tax(DST) </CardTitle>
                <SplineArea dataColors='["--bs-primary", "--bs-success"]' />
              </CardBody>
            </Card>
          </Row>
          {
            isLoading ? <Spinners setLoading={setLoading} />
              :
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>

                      <TableContainer
                        columns={columns}
                        data={transactions}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleTransactionClicks={handleTransactionClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
