

/* APPLICATIONS */
export const GET_APPLICATIONS = "GET_APPLICATIONS"
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS"
export const GET_APPLICATIONS_FAIL = "GET_APPLICATIONS_FAIL"

/**
 * add APPLICATION
 */
export const ADD_NEW_APPLICATION = "ADD_NEW_APPLICATION"
export const ADD_APPLICATION_SUCCESS = "ADD_APPLICATION_SUCCESS"
export const ADD_APPLICATION_FAIL = "ADD_APPLICATION_FAIL"

/**
 * Edit APPLICATION
 */
export const UPDATE_APPLICATION = "UPDATE_APPLICATION"
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS"
export const UPDATE_APPLICATION_FAIL = "UPDATE_APPLICATION_FAIL"

/**
 * Delete APPLICATION
 */
export const DELETE_APPLICATION = "DELETE_APPLICATION"
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS"
export const DELETE_APPLICATION_FAIL = "DELETE_APPLICATION_FAIL"

